<template>
  <div class="user-center-container"
    :class="$store.state.userInfo.vip && $store.state.userInfo.vip.available ? 'vipClass' : ''">
    <div class="user-center-box">
      <div class="user-center-setting">
        <div class="title">个人中心</div>
        <el-button class="w-82 bdr-6 fs-14 justify-content-center display-flex" size="small" @click="toVIP"
          type="primary">会员中心</el-button>
      </div>
      <div class="user-info-detail">
        <div class="user-center-info" :class="isVip ? 'bg1' : 'bg2'">
          <img :src="$store.state.userInfo.avatar" />
          <div class="info-content">
            <div class="info-name">
              <div class="info">
                <div class="name">{{ $store.state.userInfo.nickname }}</div>
                <div class="display-flex vip-box" v-if=isVip @click="toVIP">
                  <svg-icon iconClass="vip_small" class="w-14 h-14"
                    style="margin-left:7px;position: relative;top: -1px;">
                  </svg-icon>
                  <div class="vip-type">{{ vipType($store.state.userInfo.vip.vipDateType) }}VIP</div>
                </div>
                <div v-else class="none-vip" @click="toVIP">
                  <div class="text">
                    开通VIP获取权益<i class="el-icon-arrow-right"></i>
                  </div>
                </div>
              </div>
              <div class="xf" v-if="isVip" @click="toVIP">续费<i class="el-icon-arrow-right"></i></div>
            </div>
            <div class="vip-delay-time" v-if="$store.state.userInfo.vip.vipExpire !== null">
              {{ vipType($store.state.userInfo.vip.vipDateType) }}VIP有效期：{{
                  $store.state.userInfo.vip.vipExpire
              }}
            </div>
            <div class="info-change-btn" :style="{ marginTop: $store.state.userInfo.vip.vipExpire ? '' : '23px' }">
              <el-upload accept=".png, .jpeg, .jpg" :on-success="onUploadSuccess" :headers="uploadHeaders"
                :data="upLoadData" :show-file-list="false" :action="apiBaseUrl + '/common/uploadImage'">
                <el-button type="primary">更换头像</el-button>
              </el-upload>
            </div>
          </div>
        </div>
        <div class="user-info-item">
          <div class="item-left">
            <div class="item-nickname">昵称</div>
            <div class="item-name">{{ $store.state.userInfo.nickname }}</div>
          </div>
          <el-button class="w-80 bdr-4" size="small" @click="modifyNickname">编辑</el-button>
        </div>
        <div class="user-info-item">
          <div class="item-left">
            <div class="item-nickname">手机号</div>
            <div class="item-name">{{ $store.state.userInfo.phone || '绑定手机号' }}</div>
          </div>
          <el-button class="w-80 bdr-4" size="small" @click="openBindPhone"
            :disabled="$store.state.userInfo.phone && $store.state.userInfo.phone.length > 0">绑定
          </el-button>
        </div>
        <div class="user-info-item">
          <div class="item-left">
            <div class="item-nickname">微信</div>
            <div class="item-name">{{ $store.state.userInfo.bindWx ? $store.state.userInfo.wxNickname : '未绑定' }}
            </div>
          </div>
          <el-button v-if="$store.state.userInfo.bindWx" class="w-80 bdr-4" size="small" disabled>换绑</el-button>
          <el-button v-else class="w-80 bdr-4" size="small" disabled>绑定</el-button>
        </div>
        <div class="user-info-item">
          <div class="item-left">
            <div class="item-nickname">QQ</div>
            <div class="item-name">即将支持</div>
          </div>
          <el-button class="w-80 bdr-4" size="small" disabled>绑定</el-button>
        </div>
        <div class="user-info-item">
          <div class="item-left">
            <div class="item-name">下载客户端</div>
          </div>
          <el-button class="w-80 bdr-4" size="small" @click="handleToIndex">访问</el-button>
        </div>
        <div class="user-info-item">
          <div class="item-left">
            <div class="item-name">产品手册</div>
          </div>
          <el-button class="w-80 bdr-4" size="small" @click="toIntroduce">查看</el-button>
        </div>
        <div class="user-info-item">
          <div class="item-left">
            <div class="item-name">插件API</div>
          </div>
          <el-button class="w-80 bdr-4" size="small" @click="onClick">查看</el-button>
        </div>
        <div class="login-out" @click="handleLoginOut">退出登录</div>
      </div>
    </div>
    <router-view></router-view>
    <BindPhone ref="bindPhone" @bindPhoneSuccess="bindPhoneSuccess"></BindPhone>
    <el-dialog title="专属密钥" :visible.sync="dialogVisible" width="600px" :close-on-click-modal="false">
      <div class="fs-4" style="color:rgba(32,32,32,.4)">
        此功能提供于在第三方平台使用；专属密钥为用户独享，请勿泄漏；如已泄漏，请点击重置
      </div>
      <div class="display-flex align-items-center mg-t-20">
        <el-input v-model="textApi"></el-input>
        <div class="display-flex mg-l-20">
          <el-button class="w-90 bdr-6" type="primary" @click="onCopyClick">复制</el-button>
          <el-button class="w-90 bdr-6" @click="resetApi">重置</el-button>
        </div>
      </div>
      <div class="fs-18 mg-t-20 mg-b-20">二次开发</div>
      <div class="fs-14 mg-b-20" style="color:rgba(32,32,32,.4)">需要您有软件开发技术能力，您可以通过二次开发创建属于您的个人插件</div>
      <div v-html="textarea" class="textarea"></div>
    </el-dialog>
  </div>
</template>

<script>
import { copyData } from '../libs/myClipboard'
import request from '../libs/api.request'
import { showMessage } from '../libs/message'
import BindPhone from '../components/common/BindPhone.vue'

import { mapGetters } from 'vuex'


export default {
  components: {
    BindPhone,
  },
  data() {
    return {
      uploadHeaders: {},
      upLoadData: {},
      apiBaseUrl: request.baseUrl,
      showVip: false,
      dialogVisible: false,
      textarea:'',
      textApi:''
    }
  },
  methods: {
    toIntroduce(){
      window.open("https://amazenote.cn/")
    },
    onCopyClick(){
      copyData(this.textApi, 'api已复制')
    },
    onClick() {
      this.dialogVisible = true
    },
    toVIP() {
      this.$router.push('/home/userCenter/vip')
    },
    getOpenApi() {
      request.request({
        url:'/user/openKey'
      }).then(res => {
        this.textarea = res.desc
        this.textApi = res.openKey
      })
    },
    resetApi(){
      request.request({
        url:'/user/resetOpenKey'
      }).then(res => {
        this.textApi = res.openKey
      })
    },
    handleToIndex() {
      this.$router.push('/')
    },
    openBindPhone() {
      this.$refs.bindPhone.open()
    },
    bindPhoneSuccess() {
      this.$store.dispatch('modifyUserInfo')
    },
    handleLoginOut() {
      this.$confirm('是否退出登录?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$cookies.remove('authToken');
        sessionStorage.clear()
        localStorage.clear()
        location.reload();
      })
    },
    modifyNickname() {
      this.$prompt('', '修改昵称', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValue: this.$store.state.userInfo.nickname,
        beforeClose: (action, instance, done) => {
          if (action === 'confirm' && instance.inputValue && instance.inputValue !== this.$store.state.userInfo.nickname) {
            request.request({
              url: '/user/editInfo',
              method: 'post',
              data: {
                nickname: instance.inputValue,
              }
            }).then((data) => {
              this.$store.dispatch('modifyUserInfo')
              done();
            }).catch((e) => {
              showMessage({ message: e, type: 'error' })
            })
          } else {
            done();
          }
        }
      });
    },
    onUploadSuccess(response, file) {
      if (response.code === 0) {
        request.request({
          url: '/user/editInfo',
          data: {
            avatar: response.data.url
          },
          method: 'post'
        }).then(res => {
          this.$store.dispatch('modifyUserInfo')
        }).catch(err => {
          setTimeout(() => {
            showMessage({ message: err.message, type: 'error' })
          }, 200);
        })
      }
    },
  },
  computed: {
    ...mapGetters(['isVip']),
    vipType() {
      return (type) => {
        if (type === 'year') {
          return '年度'
        } else if (type === 'month') {
          return '月度'
        } else if (type === 'forever') {
          return '永久'
        }
      }
    },
  },
  created() {
    this.getOpenApi()
    this.uploadHeaders = {
      authorization: this.$cookies.get('authToken')
    }
   if(Object.keys(this.$route.query).length>0 && this.$route.query.from && this.$route.query.from === 'api') {
    this.dialogVisible = true
   }
  }
}
</script>
<style lang="scss" scoped>
.user-center-container {
  position: relative;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0;
  }

  &.vipClass {
    background: linear-gradient(360deg, #F7F7F7 0%, #FCF3E0 100%);
  }

  .user-center-box {
    .user-center-setting {
      display: flex;
      justify-content: space-between;
      margin-top: 18px;
      margin-bottom: 32px;

      .title {
        font-size: 20px;
        font-weight: 600;
        color: #00000a;
      }
    }


    .user-info-detail {
      width: 552px;
      background-color: #fff;
      border-radius: 16px 16px 12px 12px;

      .user-center-info {
        border-radius: 16px 16px 0 0;
        display: flex;
        margin-bottom: 30px;
        padding: 21px 24px;

        &.bg1 {
          background: url(../assets/images/vip_bg-head.png);
          background-size: contain;
          background-repeat: no-repeat;
        }

        &.bg2 {
          background: linear-gradient(180deg, #FFF3DC 0%, #FFFFFE 100%, #FFFFFE 100%);
        }


        img {
          width: 104px;
          height: 104px;
          border-radius: 50%;
          object-fit: cover;
        }

        .info-content {
          margin-left: 24px;
          flex: 1;

          .info-name {
            margin: 6px 0 10px;
            display: flex;
            justify-content: space-between;
            font-weight: 600;
            font-size: 20px;
            line-height: 20px;
            color: #00000a;

            .xf {
              font-size: 12px;
              font-weight: 500;
              color: #131415;

              &:hover {
                cursor: pointer;
              }
            }

            .info {
              display: flex;

              .vip-box {
                background: url(../assets/images/vip_bg-mark.png);
                background-size: contain;
                background-repeat: round;
                display: flex;
                width: 72px;
                align-items: center;
                margin-left: 8px;

                &:hover {
                  cursor: pointer;
                }

                .vip-type {
                  color: #FBDE94;
                  font-size: 12px;
                  font-weight: 600;
                  text-align: center;
                  transform: scale(0.9);
                }
              }

              .none-vip {
                margin-left: 6px;
                height: 22px;
                padding: 0 6px 0 10px;
                background-color: #202020;
                border-radius: 20px;
                color: #FBDE94;
                display: flex;
                justify-content: center;

                &:hover {
                  cursor: pointer;
                }

                .text {
                  line-height: 22px;
                  text-align: center;
                  font-size: 12px;
                  transform: scale(0.9);
                  font-weight: 600;

                  i {
                    font-size: 10px;
                  }

                }
              }
            }
          }

          .vip-delay-time {
            font-size: 12px;
            color: #131415;
            line-height: 17px;
            margin-bottom: 10px;
          }

          .info-change-btn {
            ::v-deep .el-button {
              width: 82px;
              height: 32px;
              border-radius: 6px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }

      .user-info-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 38px;
        margin-left: 24px;
        margin-right: 24px;

        .item-left {
          .item-nickname {
            font-size: 12px;
            color: rgba(32, 32, 32, 0.6);
            margin-bottom: 15px;
          }

          .item-name {
            font-size: 16px;
            line-height: 16px;
          }
        }
      }

      .login-out {
        font-size: 16px;
        height: 56px;
        line-height: 56px;
        text-align: center;
        border-top: 1px solid rgba(32, 32, 32, 0.06);

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}

::v-deep .el-dialog {
  border-radius: 12px;
}
.textarea {
  border: 1px solid #eaeaea;
  border-radius: 6px;
  background: #F7F7F7;
}
</style>
